/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        var arrow_hidden = false,
            paths_hidden = true,
            content_hidden = true;

        function display_indent_text() {
          var animate_speed = 100;
          if(arrow_hidden && paths_hidden) {
            if(content_hidden) {
              $('.s-indent__text').animate({
                opacity: 0
              }, animate_speed);
            } else {
              var $indent = $('.s-indent');
              var offset = $indent.offset();
              $('.s-indent__text').removeClass("s-indent__text--absolute").css("left", offset.left + 5).animate({
                opacity: 1
              }, animate_speed);
            }
          } else if(arrow_hidden) {
            $('.s-indent__text').addClass("s-indent__text--absolute").animate({
              opacity: 1
            }, animate_speed);
          } else {
            $('.s-indent__text').animate({
              opacity: 0
            }, animate_speed);
          }
        }

        $(window).resize(function() {
          display_indent_text();
        });

        $('.s-indent__back').click(function(e){
          e.preventDefault();
          content_hidden = true;
          $('.s-indent__text').css("opacity", 0);
          $("html, body").animate({ scrollTop: 0 }, 500);
        });

        $('.s-heading__arrow').bind('inview', function (event, visible) {
          if (visible === true) {
            arrow_hidden = false;
          } else {
            arrow_hidden = true;
          }
          display_indent_text();
        });

        $('.s-content').bind('inview', function (event, visible) {
          if (visible === true) {
            content_hidden = false;
          } else {
            content_hidden = true;
          }
          display_indent_text();
        });

        $('.s-forward').bind('inview', function (event, visible) {
          if (visible === true) {
            paths_hidden = false;
          } else {
            paths_hidden = true;
          }
          display_indent_text();
        });

        $('.s-gallery').owlCarousel({
          loop:true,
          items:1,
          autoWidth:false,
          dots:true,
          margin:1,
          responsive:{
            0:{
              nav:false,
              stagePadding:0
            },
            480:{
              nav:false,
              stagePadding:0
            },
            768:{
              nav:true,
              stagePadding:100
            }
          }
        });

        $(".s-header__menu").click(function(e){
          e.preventDefault();
          $(".s-header__primary").toggleClass("s-header__primary--open");
        });

        $(".s-product-categories > .cat-item > a").click(function(e){
          e.preventDefault();
          $(this).parent(".cat-item").toggleClass("selected");
        });

        $(".s-categories img").each(function(){
          $(this).wrap("<span class='image-filter'></span>");
        });

        $(".s-sidebar__item--product-headline img").each(function(){
          $(this).wrap("<span class='image-filter'></span>");
        });

        $(".s-product__thumbnail").click(function(e){
          e.preventDefault();
          $(".s-product__hero img").attr("src", $(this).data("url"));
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        function sfl_fade_hero(){
          $('.s-hero--image').last().delay(3000).fadeOut(1000, function(){
            $(this).prependTo(".s-heroes").css("display", "block");
            sfl_fade_hero();
          });
        }

        if($('.s-hero--image').length > 1){
          $('.s-hero--image:hidden').css("display", "block");
          sfl_fade_hero();
        }

      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'tax_product_category': {
      init: function() {
        if($(document).width() < 768){
          $('html, body').animate({
            scrollTop: $(".s-products").first().offset().top
          }, 500);
        }
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
